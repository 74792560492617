<template>
  <div class="registration container-md col-lg-8 col-md-8">
    <div class="d-flex flex-row justify-content-between">
      <div class>
        <h3 class="pb-3 px-1 mb-0 main-title text-primary">
          {{
            displayPage == 1 || displayPage == 2 || displayPage === 3
              ? "Registration"
              : isCandidateSupplier
              ? "Add Candidate"
              : "Quick Registration"
          }}
        </h3>
      </div>
    </div>
    <div v-if="displayPage == 1 && !isCandidateSupplier">
      <CCard class="mt-2">
        <CCardBody>
          <CRow>
            <CCol md="3" sm="12" class="center-align">
              <CRow
                name="email-label"
                style="height: 100%"
                :class="{ 'p-1': isMobile }"
              >
                <label
                  class="text-primary col-lg-14 col-md-14 mt-2"
                  style="font-weight: bold"
                  >Enter your email ID</label
                >
              </CRow>
            </CCol>
            <CCol md="6" sm="12" :class="{ 'py-4': isMobile }">
              <CRow name="email" style="height: 100%">
                <div class="col-12">
                  <ValidationProvider
                    rules="required|email"
                    v-slot="{ errors }"
                  >
                    <TextInput
                      name="email"
                      :value="existingCandidate.email"
                      @input="handleExistingCandidateInput"
                      :error="errors[0]"
                      autocomplete="login-email"
                    />
                  </ValidationProvider>
                </div>
              </CRow>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="3" sm="12" class="center-align">
              <CRow
                name="email-label"
                style="height: 100%"
                :class="{ 'p-1': isMobile }"
              >
                <label
                  class="text-primary col-lg-14 col-md-14 mt-2"
                  style="font-weight: bold"
                  >Enter your unique code</label
                >
              </CRow>
            </CCol>
            <CCol md="6" sm="12" :class="{ 'py-4': isMobile }">
              <CRow name="email" style="height: 100%">
                <div class="col-12">
                 <!-- <ValidationProvider
                    rules="required|email"
                    v-slot="{ errors }"
                  >
                  <TextInput
                      name="uniquecode"
                      :value="existingCandidate.email"
                      @input="handleExistingCandidateInput" 
                      :error="errors[0]"
                      autocomplete="login-email"
                    />
                   
                  </ValidationProvider>-->
                   <TextInput
                      name="uniquecode"
                      :value="uniquecode"
                    />
                </div>
              </CRow>
            </CCol>
            <CCol md="3" sm="12" :class="isMobile ? 'center-align mt-3' : ''">
              <button
                name="registration-submit"
                style="width: 100%"
                class="btn rounded-0 pl-5 pr-5 btn-primary"
                type="submit"
                @click="checkCandidateExists"
              >
                <CSpinner
                  v-if="isLoading"
                  class="spinner-border-sm text-white"
                />
                <span v-else>Next</span>
              </button>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </div>
    <div v-else-if="displayPage == 2 && !isCandidateSupplier">
      <CCard class="mt-2">
        <CCardBody>
          <CRow class="mb-3">
            <CCol
              md="3"
              sm="12"
              :class="isMobile ? 'center-align mb-3' : 'center-align'"
            >
              <CRow name="email-label" style="height: 100%">
                <label
                  class="text-primary col-lg-12 col-md-12 mt-2"
                  style="font-weight: bold"
                  >Email ID</label
                >
              </CRow>
            </CCol>
            <CCol md="6" sm="12">
              <CRow name="email" style="height: 100%">
                <div class="col-12">
                  <TextInput
                    name="email"
                    :value="existingCandidate.email"
                    :disabled="true"
                  />
                </div>
              </CRow>
            </CCol>
          </CRow>
          <CRow>
            <CCol
              md="3"
              sm="12"
              :class="isMobile ? 'center-align mb-3' : 'center-align'"
            >
              <CRow name="password-label" style="height: 100%">
                <label
                  class="text-primary col-lg-12 col-md-12 mt-2 required"
                  style="font-weight: bold"
                  >Password</label
                >
              </CRow>
            </CCol>
            <CCol md="6" sm="12">
              <CRow name="password" style="height: 100%">
                <div class="col-12">
                  <ValidationProvider
                    :rules="{
                      required: true,
                    }"
                    v-slot="{ errors }"
                    ref="existingCandidatePassword"
                  >
                    <PasswordInput
                      name="password"
                      :value="existingCandidate.password"
                      @input="handleExistingCandidateInput"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </CRow>
            </CCol>
          </CRow>
          <CRow class="my-3">
            <CCol md="2"></CCol>
            <CCol md="8">
              <p v-if="isParent" class="text-primary">
                You are currently registering to apply for a job with
                <b>{{ hospitalName }}</b
                >, Your email is already registered with one of the hospitals in
                this group. Please <b>insert your password</b> to verify your
                account and your current profile details will be registered with
                <b>{{ hospitalName }}</b
                >. Please then sign in to access your personalised portal and
                update your profile if necessary, providing as much information
                as possible to support your application.
              </p>
              <p v-else class="text-primary">
                You are currently registering to apply for a job with
                <b>{{ hospitalName }}</b
                >, which is part of the
                <b v-if="getRegisteredParent">{{ getRegisteredParent }}</b>
                group. Your email is already registered with one of the
                hospitals in this group. Please <b>insert your password</b> to
                verify your account and your current profile details will be
                registered with <b>{{ hospitalName }}</b
                >. Please then sign in to access your personalised portal and
                update your profile if necessary, providing as much information
                as possible to support your application.
              </p>
              <!--<ol id="hospital-list" class="text-primary" v-if="getRegisteredParent.length">
                <li v-for="(hospital, index) in getRegisteredParent" :key="index">
                  {{ hospital }}
                </li>
              </ol>-->
            </CCol>
            <CCol md="2"></CCol>
          </CRow>
          <div
            v-if="!isMobile"
            class="text-center mx-0 mt-2 pb-4 px-4"
            style="padding-bottom: 100px !important"
          >
            <button
              type="button"
              name="registration-home"
              class="btn rounded-0 pl-5 pr-5 btn-outline-primary float-left"
              @click="goToLogin()"
            >
              Cancel
            </button>
            <button
              style="float: right"
              name="registration-submit"
              class="btn rounded-0 pl-5 pr-5 btn-primary"
              type="submit"
              @click="onExistingCandidate"
            >
              Next
            </button>
          </div>
          <div
            v-else
            class="text-center mx-0 mt-2 pb-4"
            style="padding-bottom: 150px !important"
          >
            <CRow>
              <button
                style="width: 100%"
                type="button"
                name="registration-home"
                class="btn rounded-0 ml-3 mr-3 mt-4 btn-primary"
                @click="onExistingCandidate"
              >
                Next
              </button>
            </CRow>
            <CRow>
              <button
                style="width: 100%"
                type="button"
                name="registration-home"
                class="btn rounded-0 btn-outline-primary ml-3 mr-3 mt-4"
                @click="goToLogin()"
              >
                Cancel
              </button>
            </CRow>
          </div>
        </CCardBody>
      </CCard>
    </div>
    <div v-else-if="displayPage === 3 && !isCandidateSupplier">
      <CCard class="mt-2">
        <CCardBody>
          <CRow class="mb-3">
            <CCol
              md="3"
              sm="12"
              :class="isMobile ? 'center-align mb-3' : 'center-align'"
            >
              <CRow name="email-label" style="height: 100%">
                <label
                  class="text-primary col-lg-12 col-md-12 mt-2"
                  style="font-weight: bold"
                  >Email ID</label
                >
              </CRow>
            </CCol>
            <CCol md="6" sm="12">
              <CRow name="email" style="height: 100%">
                <div class="col-12">
                  <TextInput
                    name="email"
                    :value="existingCandidate.email"
                    :disabled="true"
                  />
                </div>
              </CRow>
            </CCol>
          </CRow>
          <CRow class="mt-3">
            <CCol md="2"></CCol>
            <CCol md="8" class="text-center">
              <p class="text-primary h6">
                You are already registered
              </p>
              <p v-if="getOrgName">
                with <b>{{ getOrgName }}</b>
              </p>
              <p></p>
              <span class="text-primary h6">{{
                getOrgVariant == Variant.campaign
                  ? "Please click below to new Sign Up"
                  : "Please click below to Sign In"
              }}</span>
            </CCol>
          </CRow>
          <CRow class="mt-3 center-align">
            <CCol md="5" class="center-align">
              <CButton class="btn-primary" @click="goToLogin">
                {{ getOrgVariant == Variant.campaign ? "Sign Up" : "Sign In" }}
              </CButton>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </div>
    <ValidationObserver
      ref="register"
      v-slot="{ handleSubmit }"
      v-else-if="displayPage == 4"
    >
      <form
        id="register"
        @submit.prevent="handleSubmit()"
        v-on:keypress.enter.prevent
        autocomplete="off"
      >
        <CCard>
          <CCardBody>
            <CRow>
              <h4 class="pb-3 d-block d-md-none main-title text-primary">
                Personal Details
              </h4>
              <div class="col-md-5 col-lg-4 col-xl-3 mb-3">
                <div class="avatar-upload">
                  <div class="avatar-preview">
                    <div :style="{ backgroundImage: 'url(' + url + ')' }"></div>
                  </div>
                  <div class="avatar-edit">
                    <input
                      id="imageUpload"
                      type="file"
                      ref="profilefileref"
                      name="image"
                      accept="image/*"
                      @change="selectedFile"
                    />
                    <label
                      for="imageUpload"
                      class="p-2 text-primary font-weight-bold"
                      >Upload Photo</label
                    >
                  </div>
                  <div v-if="isImageSelected">
                    <UploadDocument
                      class="mt-4"
                      :fileData="fileData"
                      v-on="$listeners"
                      :isProfilePic="true"
                      @closeModal="closeModal"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-7 col-lg-8 col-xl-9">
                <h4
                  class="
                    pb-3
                    main-title
                    text-primary
                    d-none d-md-block d-md-none
                  "
                >
                  Personal Details
                </h4>
                <CRow name="title">
                  <CCol md="12" lg="6">
                    <CRow class="row mb-3">
                      <label class="required col-lg-12 col-md-12">Title</label>
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <Select
                            name="title_id"
                            @input="handleChangeSelect"
                            :value="candidate.title_id"
                            :options="
                              options && options['titles']
                                ? options['titles']
                                : []
                            "
                            :taggable="false"
                            :multiple="false"
                            :clearable="false"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                  <CCol md="12" lg="6">
                    <CRow name="first_name" class="row mb-3">
                      <label class="required col-lg-12 col-md-12"
                        >First Name</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          rules="required|alpha_spaces"
                          v-slot="{ errors }"
                        >
                          <TextInput
                            name="first_name"
                            :value="candidate.first_name"
                            @input="handleInput"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                </CRow>
                <CRow name="middlename" class="mb-3">
                  <CCol md="12" lg="6">
                    <CRow class="row mb-3">
                      <label class="col-lg-12 col-md-12">Middle Name(s)</label>
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          rules="alpha_spaces"
                          v-slot="{ errors }"
                        >
                          <TextInput
                            name="middle_name"
                            :value="candidate.middle_name"
                            @input="handleInput"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                  <CCol md="12" lg="6">
                    <CRow name="surname" class="row">
                      <label class="required col-lg-12 col-md-12"
                        >Last Name</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          rules="required|alpha_spaces"
                          v-slot="{ errors }"
                        >
                          <TextInput
                            name="surname"
                            :value="candidate.surname"
                            @input="handleInput"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md="12" lg="12">
                    <CRow name="date_of_birth" class="row pb-3">
                      <label class="required col-lg-12 col-md-12"
                        >Date of Birth</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12 pr-0">
                        <ValidationProvider
                          name="dob"
                          rules="required|date_validate|no_future_date"
                          v-slot="{ errors }"
                        >
                          <DateInput
                            :onlyMonthAndYear="false"
                            name="dob"
                            :value="candidate.dob"
                            @input="handleDatePickerChange"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                  <CCol md="12" lg="6">
                    <CRow class="row mb-3">
                      <label class="required col-lg-12 col-md-12">Gender</label>
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <Select
                          ref="gender_id"
                            name="gender_id"
                            :value="candidate.gender_id"
                            @input="handleChangeSelect"
                            :options="
                              options && options['gender']
                                ? options['gender']
                                : []
                            "
                            :taggable="false"
                            :multiple="false"
                            :clearable="false"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md="12" lg="6">
                    <CRow class="row mb-3">
                      <label class="required col-lg-12 col-md-12"
                        >Nationality</label
                      >
                      <div class="col infoicon-multi-select">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <Select
                          ref="nationality_ids"
                            :name="`nationality_ids`"
                            @input="handleChangeMultiSelectAsArray"
                            :value="candidate.nationality_ids"
                            :options="
                              options && options['nationality']
                                ? options['nationality']
                                : []
                            "
                            option_label="nationality"
                            :taggable="false"
                            :multiple="true"
                            :clearable="true"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                      <div class="position-relative multi-select-icon-div">
                        <CIcon
                          name="cil-info"
                          class="hinticon-multi-select"
                          v-c-tooltip="'Select one or more'"
                        />
                      </div>
                    </CRow>
                  </CCol>
                  <CCol md="12" lg="6">
                    <CRow class="row mb-3">
                      <label class="col-lg-12 col-md-12"
                        >Currently Working In</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <Select
                          :name="`country_id`"
                          @input="handleChangeSelect"
                          :value="candidate.country_id"
                          :options="
                            options && options['locationInfo']
                              ? options['locationInfo']
                              : []
                          "
                          :taggable="false"
                          :multiple="false"
                          :clearable="true"
                        />
                      </div>
                    </CRow>
                  </CCol>
                </CRow>
              </div>
            </CRow>
          </CCardBody>
        </CCard>

        <CCard>
          <CCardBody>
            <h4 class="main-title text-primary pb-3">Contact Information</h4>
            <CRow>
              <CCol md="6">
                <CRow name="phone" class="row mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >Old Phone Number</label
                  >
                  <div class="col-lg-8 col-md-8 col-sm-8">
                    
                      <TextInput
                        name="existingphone"
                        :value="candidate.existingphone"
                        ref="existingphoneNo"
                        :disabled=true
                      />
                 
                  </div>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow name="phone" class="row mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >Phone Number</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      ref="phone"
                      :rules="{
                        required: true,
                        phone_rule: /^\+\d{1,3}\^\d{1,15}$/,
                      }"
                      v-slot="{ errors }"
                    >
                      <PhoneInput
                        name="phone"
                        :value="candidate.phone"
                        :options="
                          options && options['dialingCode']
                            ? options['dialingCode']
                            : []
                        "
                        @change="handleInput"
                        :error="errors[0]"
                        ref="phoneNo"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12" lg="6">
                <CRow
                  name="isRegisteredWhatsapp"
                  class="row mb-3 registered-whatsapp"
                >
                  <label class="col-lg-5 col-md-5 col-8 registered-whatsapp"
                    >Registered in WhatsApp</label
                  >
                  <CSwitch
                    name="isRegisteredWhatsapp"
                    color="success"
                    :checked="candidate.isRegisteredWhatsapp"
                    shape="pill"
                    variant="3d"
                    :disabled="
                      candidate.preferred_contact_method.some(
                        (el) => el.id == 'whatsapp'
                      )
                    "
                    class="ml-2 registered-whatsapp"
                    @change.native="
                      handleChangeCheck('isRegisteredWhatsapp', $event)
                    "
                  />
                </CRow>
              </CCol>
              <CCol v-if="candidate.isRegisteredWhatsapp" md="6">
                <CRow name="whatsapp" class="row mb-3">
                  <label class="col-lg-12 col-md-12 required">WhatsApp</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      ref="whatsapp"
                      :rules="{
                        phone_rule: /^\+\d{1,3}\^\d{1,15}$/,
                        required: true,
                      }"
                      v-slot="{ errors }"
                    >
                      <PhoneInputwithCountry
                        name="whatsapp"
                        :value="candidate.whatsapp"
                        :options="
                          options && options['dialingCode']
                            ? options['dialingCode']
                            : []
                        "
                        @change="handleInput"
                        :error="errors[0]"
                        ref="whatsappNo"
                      />
                      <small class="hint">
                        Please provide the phone number registered with WhatsApp
                        here.
                      </small>
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CRow name="preferred_contact_method" class="row mb-3">
                  <label class="col-lg-12 col-md-12"
                    >Preferred Contact Method</label
                  >
                  <div class="col infoicon-multi-select">
                    <Select
                      name="preferred_contact_method"
                      :value="candidate.preferred_contact_method"
                      :options="
                        options && options['preferred_contact_method']
                          ? options['preferred_contact_method']
                          : []
                      "
                      @input="handleChangeSelect"
                      :taggable="false"
                      :multiple="true"
                      :clearable="true"
                    />
                  </div>
                  <div class="position-relative multi-select-icon-div">
                    <CIcon
                      name="cil-info"
                      class="hinticon-multi-select"
                      v-c-tooltip="'Select one or more'"
                    />
                  </div>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow name="email" class="row mb-3">
                  <label class="col-lg-12 col-md-12">Email</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="email" v-slot="{ errors }">
                      <TextInput
                        name="alternate_email"
                        :value="candidate.alternate_email"
                        @change="handleInput"
                        :error="errors[0]"
                        autocomplete="new-email"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>

        <CCard v-if="!isNurse && !isAHP">
          <CCardBody>
            <h4 class="main-title text-primary pb-3">
              Professional Information
            </h4>
            <CRow>
              <CCol md="6">
                <CRow name="candidate_type" class="row mb-3">
                  <label class="required col-lg-12 col-md-12">Profession</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="candidate_type_id"
                        :value="candidate.candidate_type_id"
                        @input="handleChangeSelect"
                        :options="
                          options && options['candidateType']
                            ? options['candidateType']
                            : []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" v-if="isNonClinicAdmin">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12 required">Level</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="level_id"
                        :value="candidate.level_id"
                        @input="handleChangeSelect"
                        :options="
                          options && options['candidateLevel']
                            ? options['candidateLevel']
                            : []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                        :showTooltip="true"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" v-if="isNonClinicAdmin">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12"
                    >Current Job Title / Role</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <TextInput
                      name="current_job"
                      :value="candidate.current_job"
                      @change="handleInput"
                    />
                  </div>
                </CRow>
              </CCol>
              <CCol v-if="isNonClinicAdmin" md="6">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12">
                    Years in Role (<b v-if="candidate.years_at_level != null">{{
                      candidate.years_at_level + " "
                    }}</b
                    >yrs)
                  </label>
                  <div
                    class="
                      ml-1
                      mr-1
                      col-lg-12 col-md-12 col-sm-12
                      years_in_role
                    "
                  >
                    <v-slider
                      name="years_in_role"
                      :min="0"
                      :max="20"
                      :value="candidate.years_at_level"
                      v-model="candidate.years_at_level"
                    ></v-slider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="12" lg="6">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12 required"
                    >Highest Qualification Obtained In</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="main_qualification_country_id"
                        :value="candidate.main_qualification_country_id"
                        @input="handleChangeSelect"
                        :options="
                          options && options['locationInfo']
                            ? options['locationInfo']
                            : []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12 required">{{
                    isNonClinicAdmin
                      ? "Highest Qualification"
                      : "Qualification Name"
                  }}</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="main_qualification_id"
                        :value="candidate.main_qualification_id"
                        @input="handleChangeSelect"
                        :options="
                          options && options['qualification']
                            ? options['qualification']
                            : []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <CRow>
                  <CCol lg="12" v-if="mainQualificationIsOther">
                    <CRow name="other_qualification_name" class="row mb-3">
                      <label class="required col-lg-12 col-md-12"
                        >Other Qualification ( Level / Subject )</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <TextInput
                            name="other_qualification_name"
                            :value="candidate.other_qualification_name"
                            @input="handleInput"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                  <CCol
                    md="12"
                    v-if="
                      candidate.main_qualification_id &&
                        candidate.main_qualification_id.allow_subject_input ==
                          true
                    "
                  >
                    <CRow name="qualification_subject_name" class="row mb-3">
                      <label class="col-lg-12 col-md-12"
                        >Qualification Subject</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <TextInput
                          name="main_qualification_description"
                          :value="candidate.main_qualification_description"
                          @input="handleInput"
                        />
                      </div>
                    </CRow>
                  </CCol>
                  <CCol
                    lg="12"
                    v-if="
                      candidate.main_qualification_id
                        ? candidate.main_qualification_id.by_exam
                        : false
                    "
                  >
                    <CRow class="row mb-3">
                      <label
                        name="lbl_by_exam"
                        class="required col-lg-12 col-md-12"
                        >Is This By Exam?</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <RadioButton
                            name="main_qualification_by_exam"
                            :value="candidate.main_qualification_by_exam"
                            :options="
                              options && options['byExam']
                                ? options['byExam']
                                : []
                            "
                            @change="handleChangeRadio"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12" lg="6">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12 required"
                    >Month / Year Awarded</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      rules="no_future_date|dob_check:@dob|required"
                      v-slot="{ errors }"
                    >
                      <DateInput
                        :onlyMonthAndYear="true"
                        name="qualified_date"
                        :value="candidate.qualified_date"
                        @change="handleDatePickerChange"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <!--<CRow>
              <CCol md="6">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12 required">Issued By</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="main_qualification_issue_authority_id"
                        :value="candidate.main_qualification_issue_authority_id"
                        @input="handleChangeSelect"
                        :options="
                          options && options['issued_by']
                            ? options['issued_by']
                            : []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol
                md="6"
                v-if="payload.main_qualification_issue_authority_id == 'Others'"
              >
                <CRow
                  name="main_qualification_other_issue_authority"
                  class="row mb-3"
                >
                  <label class="required col-lg-12 col-md-12"
                    >Other Issuing Authority</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="main_qualification_other_issue_authority"
                        :value="
                          candidate.main_qualification_other_issue_authority
                        "
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>-->
            <CRow>
              <CCol
                md="6"
                v-if="
                  candidate.main_qualification_id &&
                    candidate.main_qualification_id.specialist_registration_reqd
                "
              >
                <CRow class="row mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >Are you on a Specialist Register?</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <RadioButton
                        name="specialist_registration"
                        :value="candidate.specialist_registration"
                        :options="
                          options && options['specialistRegister']
                            ? options['specialistRegister']
                            : []
                        "
                        @change="handleChangeRadio"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" v-if="candidate.specialist_registration == true">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12 required"
                    >Acquired Date</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      rules="required|no_future_date|dob_check:@dob"
                      v-slot="{ errors }"
                    >
                      <DateInput
                        :onlyMonthAndYear="true"
                        name="acquired_date"
                        :value="acquired_date"
                        @input="handleAcquiredDate"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <!--<CRow>
              <CCol md="6">
                <CRow class="row mb-3">
                  <label
                    class="col-lg-12 col-md-12"
                    :class="
                      candidate.main_qualification_id
                        ? candidate.main_qualification_id.course_duration_reqd
                          ? 'required'
                          : false
                        : false
                    "
                  >
                    Course Duration (<b
                      v-if="
                        candidate.main_qualification_course_duration != null
                      "
                      >{{
                        candidate.main_qualification_course_duration + " "
                      }}</b
                    >yrs)
                  </label>
                  <div
                    class="
                      ml-1
                      mr-1
                      col-lg-12 col-md-12 col-sm-12
                      course_duration
                    "
                  >
                    <v-slider
                      name="main_qualification_course_duration"
                      :min="0"
                      :max="10"
                      :value="candidate.main_qualification_course_duration"
                      @change="handleSlider"
                    ></v-slider>
                  </div>
                </CRow>
              </CCol>
            </CRow>-->
            <CRow>
              <CCol md="6">
                <CRow name="speciality" class="row mb-3">
                  <label class="required col-lg-12 col-md-12">Speciality</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                      ref="speciality_id"
                        name="speciality_id"
                        :value="candidate.speciality_id"
                        @input="handleChangeSelect"
                        :options="
                          options && options['speciality']
                            ? options['speciality']
                            : []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" v-if="options['subSpeciality'].length">
                <CRow name="sub_speciality" class="row mb-3">
                  <label class="col-lg-12 col-md-12">{{
                    isNurse ? `Area of Expertise` : `Sub Speciality`
                  }}</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <Select
                      name="sub_speciality_id"
                      :value="candidate.sub_speciality_id"
                      @input="handleChangeSelect"
                      :options="
                        options && options['subSpeciality']
                          ? options['subSpeciality']
                          : []
                      "
                      :taggable="false"
                      :multiple="false"
                      :clearable="true"
                    />
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6" v-if="specialityIsOther">
                <CRow name="other_speciality" class="row mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >Other Speciality</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="other_speciality"
                        :value="candidate.other_speciality"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>

        <CCard v-if="isNurse || isAHP">
          <CCardBody>
            <h4 class="main-title text-primary pb-3">
              Professional Information
            </h4>
            <CRow>
              <CCol md="6">
                <CRow name="candidate_type" class="row mb-3">
                  <label class="required col-lg-12 col-md-12">Profession</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="candidate_type_id"
                        :value="candidate.candidate_type_id"
                        @input="handleChangeSelect"
                        :options="
                          options && options['candidateType']
                            ? options['candidateType']
                            : []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" v-if="isNurse">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12 required"
                    >Nurse / Midwifery Level</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="level_id"
                        :value="candidate.level_id"
                        @input="handleChangeSelect"
                        :options="
                          options && options['candidateLevel']
                            ? options['candidateLevel']
                            : []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                        :showTooltip="true"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12 required"
                    >First Qualification Obtained In</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="first_qualification_country_id"
                        :value="candidate.first_qualification_country_id"
                        @input="handleChangeSelect"
                        :options="
                          options && options['locationInfo']
                            ? options['locationInfo']
                            : []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12 required"
                    >First Qualification Name</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="first_qualification_id"
                        :value="candidate.first_qualification_id"
                        @input="handleChangeSelect"
                        :options="
                          options && options['firstQualification']
                            ? options['firstQualification']
                            : []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol
                md="6"
                v-if="
                  candidate.first_qualification_id &&
                    candidate.first_qualification_id.allow_subject_input == true
                "
              >
                <CRow name="first_qualification_subject_name" class="row mb-3">
                  <label class="col-lg-12 col-md-12 required"
                    >Qualification Subject</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="first_qualification_description"
                        :value="candidate.first_qualification_description"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol lg="6" v-if="firstQualificationIsOther">
                <CRow name="first_other_qualification_name" class="row mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >Other Qualification ( Level / Subject )</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="first_other_qualification_name"
                        :value="candidate.first_other_qualification_name"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol
                lg="6"
                v-if="
                  candidate.first_qualification_id
                    ? candidate.first_qualification_id.by_exam
                    : false
                "
              >
                <CRow class="row mb-3">
                  <label name="lbl_by_exam" class="required col-lg-12 col-md-12"
                    >Is This By Exam?</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <RadioButton
                        name="first_qualification_by_exam"
                        :value="candidate.first_qualification_by_exam"
                        :options="
                          options && options['byExam'] ? options['byExam'] : []
                        "
                        @change="handleChangeRadio"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12 required">
                    Course Duration (<b
                      v-if="
                        candidate.first_qualification_course_duration != null
                      "
                      >{{
                        candidate.first_qualification_course_duration + " "
                      }}</b
                    >yrs)
                  </label>
                  <div
                    class="
                      ml-1
                      mr-1
                      col-lg-12 col-md-12 col-sm-12
                      first_course_duration
                    "
                  >
                    <v-slider
                      name="first_qualification_course_duration"
                      :min="0"
                      :max="10"
                      :value="candidate.first_qualification_course_duration"
                      v-model="candidate.first_qualification_course_duration"
                    ></v-slider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12 required"
                    >Month / Year Awarded</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      rules="no_future_date|dob_check:@dob|required"
                      v-slot="{ errors }"
                    >
                      <DateInput
                        :onlyMonthAndYear="true"
                        name="first_qualified_date"
                        :value="candidate.first_qualified_date"
                        @change="handleDatePickerChange"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <CRow class="row mb-3">
                  <label
                    name="lbl_have_higher_qualification"
                    class="required col-lg-12 col-md-12"
                    >Have you a Higher Medical Qualification?</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <RadioButton
                        name="is_have_higher_qualification"
                        :value="candidate.is_have_higher_qualification"
                        :options="
                          options && options['haveHigherQualification']
                            ? options['haveHigherQualification']
                            : []
                        "
                        @change="handleChangeRadio"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <div v-if="payload.is_have_higher_qualification">
              <CRow>
                <CCol md="6">
                  <CRow class="row mb-3">
                    <label class="col-lg-12 col-md-12 required"
                      >Highest Qualification Obtained In</label
                    >
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <Select
                          name="main_qualification_country_id"
                          :value="candidate.main_qualification_country_id"
                          @input="handleChangeSelect"
                          :options="
                            options && options['locationInfo']
                              ? options['locationInfo']
                              : []
                          "
                          :taggable="false"
                          :multiple="false"
                          :clearable="false"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
                <CCol md="6"> </CCol>
              </CRow>
              <CRow>
                <CCol md="6">
                  <CRow class="row mb-3">
                    <label
                      class="col-lg-12 col-md-12 required main_qualification"
                      >Qualification Name</label
                    >
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <Select
                          name="main_qualification_id"
                          :value="candidate.main_qualification_id"
                          @input="handleChangeSelect"
                          :options="
                            options && options['higherQualification']
                              ? options['higherQualification']
                              : []
                          "
                          :taggable="false"
                          :multiple="false"
                          :clearable="false"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
                <CCol md="6" v-if="mainQualificationIsOther">
                  <CRow name="main_other_qualification_name" class="row mb-3">
                    <label class="required col-lg-12 col-md-12"
                      >Other Qualification ( Level / Subject )</label
                    >
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <TextInput
                          name="other_qualification_name"
                          :value="candidate.other_qualification_name"
                          @input="handleInput"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
                <CCol
                  md="6"
                  v-if="
                    candidate.main_qualification_id &&
                      candidate.main_qualification_id.allow_subject_input ==
                        true
                  "
                >
                  <CRow name="qualification_subject_name" class="row mb-3">
                    <label class="col-lg-12 col-md-12 required"
                      >Qualification Subject</label
                    >
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <TextInput
                          name="main_qualification_description"
                          :value="candidate.main_qualification_description"
                          @input="handleInput"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
              </CRow>
              <CRow>
                <CCol md="6">
                  <CRow class="row mb-3">
                    <label class="col-lg-12 col-md-12 required">
                      Course Duration (<b
                        v-if="
                          candidate.main_qualification_course_duration != null
                        "
                        >{{
                          candidate.main_qualification_course_duration + " "
                        }}</b
                      >yrs)
                    </label>
                    <div
                      class="
                        ml-1
                        mr-1
                        col-lg-12 col-md-12 col-sm-12
                        course_duration
                      "
                    >
                      <v-slider
                        name="main_qualification_course_duration"
                        :min="0"
                        :max="10"
                        :value="candidate.main_qualification_course_duration"
                        v-model="candidate.main_qualification_course_duration"
                      ></v-slider>
                    </div>
                  </CRow>
                </CCol>
                <CCol md="6">
                  <CRow class="row mb-3">
                    <label class="col-lg-12 col-md-12 required"
                      >Month / Year Awarded</label
                    >
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider
                        rules="no_future_date|dob_check:@dob|required"
                        v-slot="{ errors }"
                      >
                        <DateInput
                          :onlyMonthAndYear="true"
                          name="qualified_date"
                          :value="candidate.qualified_date"
                          @change="handleDatePickerChange"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
              </CRow>
            </div>
            <CRow v-if="isNurse">
              <CCol md="6">
                <CRow name="registered_as" class="row mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >Are you Licensed/Registered as</label
                  >
                  <div
                    class="col-lg-12 col-md-12 col-sm-12"
                    v-for="({ code, label }, index) in nurseTypes"
                    :key="index"
                  >
                    <CRow>
                      <label class="col-lg-6 col-md-6">{{ label }}</label>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <RadioButton
                          :name="code"
                          :value="candidate[code]"
                          :options="
                            options && options['boolean']
                              ? options['boolean']
                              : []
                          "
                          @change="handleChangeRadio"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </CRow>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CRow name="speciality" class="row mb-3">
                  <label class="required col-lg-12 col-md-12">Speciality</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="speciality_id"
                        :value="candidate.speciality_id"
                        @input="handleChangeSelect"
                        :options="
                          options && options['speciality']
                            ? options['speciality']
                            : []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12 required">
                    Number of years in Speciality (<b
                      v-if="candidate.speciality_duration != null"
                      >{{ candidate.speciality_duration + " " }}</b
                    >yrs)
                  </label>
                  <div
                    class="
                      ml-1
                      mr-1
                      col-lg-12 col-md-12 col-sm-12
                      speciality_duration
                    "
                  >
                    <v-slider
                      name="speciality_duration"
                      :min="0"
                      :max="20"
                      :value="candidate.speciality_duration"
                      v-model="candidate.speciality_duration"
                    ></v-slider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6" v-if="specialityIsOther">
                <CRow name="other_speciality" class="row mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >Other Speciality</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="other_speciality"
                        :value="candidate.other_speciality"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6" v-if="isNurse">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12"
                    >Other areas of expertise<small
                      class="text-primary ml-1 font-weight-bold"
                      >(select max 3)</small
                    ></label
                  >
                  <div class="col-lg-12 col-md-12">
                    <Select
                      :name="`other_areas_of_expertise_ids`"
                      @input="handleChangeMultiSelectAsArray"
                      :value="candidate.other_areas_of_expertise_ids"
                      :options="
                        options && options['speciality']
                          ? options['speciality']
                          : []
                      "
                      option_label="label"
                      :taggable="false"
                      :multiple="true"
                      :clearable="true"
                      :limit="3"
                    />
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" v-if="isAHP && options['subSpeciality'].length">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12"
                    >Sub Type / Licensed as</label
                  >
                  <div class="col infoicon-multi-select">
                    <Select
                      :name="`sub_speciality_id`"
                      @input="handleChangeSelect"
                      :value="candidate.sub_speciality_id"
                      :options="
                        options && options['subSpeciality']
                          ? options['subSpeciality']
                          : []
                      "
                      option_label="label"
                      :taggable="false"
                      :multiple="false"
                      :clearable="true"
                    />
                  </div>
                </CRow>
              </CCol>
              <!--<CCol md="6" v-if="isAHP">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12"
                    >Other areas of expertise<small
                      class="text-primary ml-1 font-weight-bold"
                      >(select max 3)</small
                    ></label
                  >
                  <div class="col-lg-12 col-md-12">
                    <Select
                      :name="`other_areas_of_expertise_ids`"
                      @input="handleChangeMultiSelectAsArray"
                      :value="candidate.other_areas_of_expertise_ids"
                      :options="
                        options && options['specialInterest']
                          ? options['specialInterest']
                          : []
                      "
                      option_label="label"
                      :taggable="false"
                      :multiple="true"
                      :clearable="true"
                      :limit="3"
                    />
                  </div>
                </CRow>
              </CCol>-->
              <CCol md="6" v-if="isOther">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12 required"
                    >Other areas of expertise Name</label
                  >
                  <div class="col-lg-12 col-md-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="other_areas_of_expertise_name"
                        @input="handleInput"
                        :value="candidate.other_areas_of_expertise_name"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>

        <CCard>
          <CCardBody>
            <h4 class="main-title text-primary pb-3">Registration Details</h4>
            <CRow name="email" class="row mb-3">
              <label class="required col-lg-12 col-md-12">Email</label>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <ValidationProvider rules="required|email" v-slot="{ errors }">
                  <TextInput
                    name="email"
                    :value="candidate.email"
                    @input="handleInput"
                    @change="handleEmailChange"
                    :error="errors[0]"
                    autocomplete="login-email"
                    :disabled="isCandidateSupplier ? false : true"
                  />
                </ValidationProvider>
              </div>
            </CRow>
            <CRow>
              <CCol md="6">
                <CRow name="password" class="row mb-3">
                  <label class="required col-lg-12 col-md-12">Password</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      name="password"
                      :rules="{
                        required: true,
                        password_length: 8,
                        password_strength: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                      }"
                      v-slot="{ errors }"
                    >
                      <PasswordInput
                        name="password"
                        :value="candidate.password"
                        @input="handleInput"
                        :error="errors[0]"
                        autocomplete="new-password"
                      />
                      <small class="hint">
                        Password format: 8 characters - containing upper & lower
                        case letters, numbers and a special character. No words.
                      </small>
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow name="confirm_password" class="row mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >Confirm Password</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      rules="required|confirmed:password"
                      v-slot="{ errors }"
                    >
                      <PasswordInput
                        name="confirmPassword"
                        :value="candidate.confirmPassword"
                        @input="handleInput"
                        :error="errors[0]"
                        autocomplete="comfirm-password"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <h5 class="text-primary">
              Please Upload your CV and other Documents
            </h5>
            <CRow class="row mb-3 border-top mt-4 pt-3 pb-1">
              <CCol md="6">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12"
                    >Select Document Type</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <Select
                      name="document_type"
                      :value="candidate.document_type || 'CV / Resume'"
                      @input="handleChangeSelect"
                      :options="
                        options && options['document_type']
                          ? options['document_type']
                          : []
                      "
                      :taggable="false"
                      :multiple="false"
                      :clearable="false"
                    />
                  </div>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12">Upload Document</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <input
                      name="upload-document"
                      type="file"
                      ref="docfileref"
                      :accept="fileType"
                      @change="handleFile"
                    />
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow
              class="
                ml-2
                mr-2
                d-flex
                align-items-center
                documentScrolling
                document-table
              "
              v-if="uploadFiles.length !== 0"
            >
              <table class="table">
                <thead>
                  <tr>
                    <th class="w-25">Document Type</th>
                    <th>Document Name</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in uploadFiles" :key="index">
                    <td>{{ data.docType.label }}</td>
                    <td>
                      {{ data.file.name }}
                      <button
                        type="button"
                        v-if="
                          ['PNG', 'JPEG', 'JPG', 'GIF', 'PDF'].includes(
                            data.fileExt
                          )
                        "
                        class="hand-icon action-button"
                        @click="preview(data)"
                      >
                        <i class="fas fa-eye ml-3"></i>
                      </button>
                      <button
                        type="button"
                        v-if="data.isTemp"
                        class="hand-icon action-button"
                        @click="previewTemp(data)"
                      >
                        <i class="fas fa-eye ml-3"></i>
                      </button>
                    </td>
                    <td>
                      <button
                        type="button"
                        class="hand-icon action-button"
                        @click="deleteDocuments(data.file.name, index)"
                      >
                        <i class="fas fa-trash ml-3"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </CRow>
          </CCardBody>
        </CCard>
        <CCard>
          <CCardBody>
            <div>
              <input
                type="checkbox"
                class="mr-2"
                ref="termPrivacyCheck"
                :checked="isTermPrivacyChecked"
                @change="isTermPrivacyChecked = !isTermPrivacyChecked"
              />
              <span v-if="!isMobile"
                >I have read and agree to the
                <b>
                  <a href="javascript:void(0)" @click="showTermPopup"
                    >Terms and Privacy Policy</a
                  ></b
                >. By registering your profile and personal details you consent
                and agree to receiving updates and information relating to work
                opportunities. You have the option to <b>unsubscribe</b> at any
                time.
              </span>
              <span v-else>
                I have read and agree to the
                <b>
                  <a href="javascript:void(0)" @click="showTermPopup"
                    >Terms and Privacy Policy</a
                  ></b
                >
              </span>
            </div>
          </CCardBody>
        </CCard>
        <CRow style="padding-bottom: 79px !important">
          <CCol v-if="!isCandidateSupplier" sm="12" md="3" lg="3">
            <button
              type="button"
              name="registration-home"
              class="
                btn
                rounded-2
                px-5
                btn-lg
                float-left
                btn-outline-primary btn-lg
                mb-2
                w-100
              "
              @click="goToLogin()"
            >
              Home
            </button>
          </CCol>
          <CCol v-else sm="12" md="3" lg="3">
            <button
              type="button"
              name="registration-home"
              class="
                btn
                rounded-2
                px-5
                btn-lg
                float-left
                btn-outline-primary btn-lg
                mb-2
                w-100
              "
              @click="$router.push('/candidate-list')"
            >
              Cancel
            </button>
          </CCol>
          <CCol sm="12" lg="0" md="3"> </CCol>
          <CCol sm="12" lg="3" md="3">
            <button
              name="registration-cancel"
              class="btn rounded-2 px-5 btn-secondary btn-lg w-100 mb-2"
              type="button"
              @click="resetForm"
            >
              Clear
            </button>
          </CCol>
          <CCol sm="12" lg="3" md="3">
            <button
              name="registration-submit"
              class="btn rounded-2 px-5 btn-primary btn-lg w-100 mb-2"
              type="submit"
              @click="onSubmit"
            >
              Submit
            </button>
          </CCol>
        </CRow>
      </form>
    </ValidationObserver>

    <CToaster v-if="isFileError" :autohide="3000" position="top-right">
      <CToast :show="true" class="bg-danger text-white"
        >File too Big, please select a file less than 2mb</CToast
      >
    </CToaster>
    <Modal
      :modalTitle="deleteConfirmModal.modalTitle"
      :modalColor="deleteConfirmModal.modalColor"
      :modalContent="deleteConfirmModal.modalContent"
      :isShowPopup="deleteConfirmModal.isShowPopup"
      :buttons="deleteConfirmModal.buttons"
      :modalCallBack="deletemodalCallBack"
      :closeOnBackdrop="false"
    />
    <Modal
      :modalTitle="emailConfirmModal.modalTitle"
      :modalColor="emailConfirmModal.modalColor"
      :modalContent="emailConfirmModal.modalContent"
      :isShowPopup="emailConfirmModal.isShowPopup"
      :buttons="emailConfirmModal.buttons"
      :modalCallBack="emailmodalCallBack"
      :closeOnBackdrop="false"
    />
    <Modal
      :modalTitle="termConfirmModal.modalTitle"
      :modalColor="termConfirmModal.modalColor"
      :modalContent="termConfirmModal.modalContent"
      :isShowPopup="termConfirmModal.isShowPopup"
      :buttons="termConfirmModal.buttons"
      :modalCallBack="termmodalCallBack"
      :closeOnBackdrop="false"
    />
    <Modal
      :modalTitle="alreadyRegisteredModal.modalTitle"
      :modalColor="alreadyRegisteredModal.modalColor"
      :modalContent="alreadyRegisteredModal.modalContent"
      :isShowPopup="alreadyRegisteredModal.isShowPopup"
      :buttons="alreadyRegisteredModal.buttons"
      :modalCallBack="proceedToMultiRegistration"
      :closeOnBackdrop="false"
      :innerHTML="alreadyRegisteredModal.innerHTML"
    />
    <PreviewModal
      :modalTitle="previewModal.modalTitle"
      :modalColor="previewModal.modalColor"
      :isShowPopup="previewModal.isShowPopup"
      :buttons="previewModal.buttons"
      :modalCallBack="modalCallBack"
      :isPreview="previewModal.isPreview"
      :iframe="iframe"
      :size="previewModal.size"
      :closeOnBackdrop="previewModal.closeOnBackdrop"
    />
  </div>
</template>

<script>
const debounce = require("lodash.debounce");
import _ from "lodash";
import Vue from "vue";
import m from "moment";
import pdf from "vue-pdf";
import { extend } from "vee-validate";
import {
  required,
  email,
  confirmed,
  regex,
  min,
  numeric,
  alpha_spaces,
} from "vee-validate/dist/rules";
import PreviewModal from "@/components/reusable/PreviewModal";
import { mapGetters, mapActions } from "vuex";
import { BASE_URL } from "@/service_urls";

extend("required", { ...required, message: "This field is required" });
extend("password_length", {
  ...min,
  message: "Password be atleast 8 characters",
});
extend("password_strength", {
  ...regex,
  message: "Password must have capitals, numbers and special characters",
});
extend("confirmed", {
  ...confirmed,
  message: "This field should match password",
});
extend("email", { ...email, message: "Invalid email" });
extend("numeric", { ...numeric, message: "Only numbers are allowed" });
extend("phone_rule", {
  ...regex,
  message: "Only numbers are allowed(Maximum of 15 digits)",
});
extend("date_validate", (value) => {
  if (m(value).isValid()) {
    return true;
  }
  return "Invalid date! Enter a valid date";
});
extend("alpha_spaces", {
  ...alpha_spaces,
  message: "Only Alphabets are allowed",
});
extend("no_future_date", (value) => {
  let input_date = new Date(value);
  let today_date = new Date();
  if (input_date > today_date) {
    return "Given date should not be greater than today!";
  }
  return true;
});
extend("dob_check", {
  params: ["dob"],
  validate(value, { dob }) {
    let birth_date = new Date(dob);
    let input_date = new Date(value);
    if (!(input_date < birth_date)) {
      return true;
    }
  },
  message: "Given date should be between birthdate & today!",
});

import Select from "@/components/reusable/Fields/Select.vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import PhoneInput from "@/components/reusable/Fields/PhoneInput";
import PhoneInputwithCountry from "@/components/reusable/Fields/PhoneInputwithCountry";
import RadioButton from "@/components/reusable/Fields/RadioButton";
import DateInput from "@/components/reusable/Fields/DateInput";
import UploadDocument from "@/containers/CandidateProfile/UploadDocument";
import Modal from "@/components/reusable/Modal";
import { LOGIN_URL, getLocalOrgDetail, Variant } from "../../helpers/helper";
import PasswordInput from "@/components/reusable/Fields/PasswordInput";

export default {
  components: {
    Select,
    TextInput,
    PhoneInput,
    PhoneInputwithCountry,
    RadioButton,
    DateInput,
    UploadDocument,
    Modal,
    PreviewModal,
    PasswordInput,
  },
  props: {
    customer: {
      type: Number,
      default: null,
    },
    isCandidateSupplier: {
      type: Boolean,
      default: false,
    },
    organisationID: {
      type: Number,
    },
    locationID: {
      type: Number,
    },
    profilePhoto: {},
  },
  data() {
    return {
      uniquecode:0,
      Variant,
      isFileError: false,
      confirmPassword: null,
      candidate: {
        preferred_contact_method: [],
        isRegisteredWhatsapp: false,
        is_have_higher_qualification: false,
        share_profile: false,
      },
      payload: {
        customer_id: this.customer,
        requires_login: true,
        volunteer: false,
        retired_clinician: false,
        is_have_higher_qualification: false,
        share_profile: false,
      },
      isImageSelected: false,
      fileData: {},
      url: "/img/user_default_pic.png",
      uploadFiles: [],
      deleteConfirmModal: {
        modalColor: "danger",
        modalTitle: "NOTIFICATION",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
      emailConfirmModal: {
        modalColor: "danger",
        modalTitle: "NOTIFICATION",
        modalContent:
          "Is this your contact email as it is different from above. Please confirm...",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
      document_id: "",
      acquired_date: null,
      previewModal: {
        modalColor: "primary",
        modalTitle: "",
        isShowPopup: false,
        buttons: ["Close"],
        isPreview: false,
        size: "lg",
        closeOnBackdrop: false,
      },
      iframe: {
        src: "",
        loaded: false,
        isImg: false,
        pdf: {
          ispdf: true,
          src: "",
          numPages: undefined,
          addContentClasses: "",
        },
      },
      isTermPrivacyChecked: false,
      termConfirmModal: {
        modalColor: "danger",
        modalTitle: "NOTIFICATION",
        modalContent: "Please accept Terms and Privacy Policy",
        isShowPopup: false,
        buttons: ["OK"],
      },
      isMobile: false,
      alreadyRegisteredModal: {
        modalColor: "danger",
        modalTitle: "NOTIFICATION",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Continue"],
      },
      existingCandidate: {},
      displayPage: this.isCandidateSupplier ? 4 : 1,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters([
      "getOrgDetail",
      "getUser",
      "getUserContactId",
      "gender",
      "locationCountryInfo",
      "nationalityCountryInfo",
      "candidateType",
      "specialityFilterByType",
      "specialityByTypeAndCountry",
      "subSpecialityFilterBySpeciality",
      "qualificationByCountryAndCandidateType",
      "issueAuthorityByQualification",
      "uploaded",
      "documentTypeExcPhoto",
      "dialingCodeWithCountryCode",
      "titles",
      "getFilteredIssueAuthority",
      "getDepartmentsByOrganisationId",
      "getSkillsByCandidateType",
      "candidateLevelFilterByTypeCountry",
      "specialInterestFilterAHP",
      "firstQualificationByCountryAndCandidateType",
      "higherQualificationByCountryAndCandidateType",
      "nurseRegistrationType",

      // get filtered issue authority based on candidate and country id
      "getFilteredIssueAuthorityByCandidateTypeAndCountry",
      "getRegisteredParent",
      "randomUuid",
      "getOrgVariant",
      "ohrcandidateProfile",
    ]),
    getOrgName() {
      return this.getOrgDetail?.name;
    },
    documentTypeExcPhotoCustomOrder() {
      return (
        (this.documentTypeExcPhoto &&
          this.documentTypeExcPhoto.length &&
          this.documentTypeExcPhoto.sort((a, b) =>
            a.label === "CV / Resume" ? -1 : 1
          )
          .filter(({ code }) => ![23, 24].includes(code))
          ) ||
        []
      );
    },
    nationalityOptions() {
      return (
        (this.nationalityCountryInfo &&
          this.candidate &&
          this.candidate["nationality_ids"] &&
          this.nationalityCountryInfo.filter((val) => {
            return !this.candidate["nationality_ids"].some(
              ({ code }) => code === val.code
            );
          })) ||
        this.nationalityCountryInfo
      );
    },
    options() {
      return {
        gender: this.gender || [],
        locationInfo: this.locationCountryInfo || [],
        nationality: this.nationalityOptions || [],
        titles: this.titles || [],
        dialingCode: this.dialingCodeWithCountryCode || [],
        candidateType: this.candidateType || [],
        speciality:
          this.specialityByTypeAndCountry({
            candidateType: this.payload.candidate_type_id,
            country_id: this.payload.main_qualification_country_id,
          }) || [],
        subSpeciality:
          this.subSpecialityFilterBySpeciality({
            speciality: this.payload.speciality_id,
          }) || [],
        qualification: this.qualificationByCountryAndCandidateType || [],
        issued_by:
          this.issueAuthorityByQualification({
            // country_id: this.payload.main_qualification_country_id,
            qualification_id: this.payload.main_qualification_id,
          }) || [],
        // issued_by: this.getFilteredIssueAuthorityByCandidateTypeAndCountry || [],
        document_type: this.documentTypeExcPhotoCustomOrder || [],
        preferred_contact_method: [
          { id: "phone", label: "Phone" },
          { id: "whatsapp", label: "WhatsApp" },
          { id: "email", label: "Email" },
          { id: "sms", label: "SMS" },
        ],
        document_verification_status: [
          { id: "none", label: "None" },
          { id: "some", label: "Some" },
          { id: "all", label: "All" },
          { id: "n/a", label: "N/A" },
        ],
        specialistRegister: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
        byExam: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
        licence:
          this.specialityByTypeAndCountry({
            candidateType: this.payload.candidate_type_id,
            country_id: this.payload.first_qualification_country_id,
          }) || [],
        skills:
          this.getSkillsByCandidateType({
            candidate_type_id: this.payload.candidate_type_id,
          }) || [],
        departments: this.getDepartmentsByOrganisationId || [],
        first_qualification_issued_by:
          this.issueAuthorityByQualification({
            // country_id: this.payload.first_qualification_country_id,
            qualification_id: this.payload.first_qualification_id,
          }) || [],
        // first_qualification_issued_by: this.getFilteredIssueAuthorityByCandidateTypeAndCountry || [],
        haveHigherQualification: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
        candidateLevel:
          this.candidateLevelFilterByTypeCountry({
            candidateType: this.payload?.candidate_type_id,
            country_id: null,
          }) || [],
        specialInterest: this.specialInterestFilterAHP({
          candidate_type_id: this.payload.candidate_type_id,
          speciality: null,
        }),
        firstQualification: this.firstQualificationByCountryAndCandidateType,
        higherQualification: this.higherQualificationByCountryAndCandidateType,
        boolean: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
      };
    },
    
   
    fileType() {
      if (this.candidate.document_type && this.candidate.document_type.code) {
        let fileext = this.documentTypeExcPhoto
          .filter(({ code }) => code === this.candidate.document_type.code)
          .map((d) => d.allowed_extensions)[0];
        fileext = fileext.map((d) => d.toLowerCase());
        return this.arraytoComma(fileext);
      } else {
        let fileexts = this.documentTypeExcPhoto
          .filter(({ code }) => code === 1)
          .map((d) => d.allowed_extensions);
        let fileext = fileexts.length ? fileexts[0] : [];
        fileext = fileext.map((d) => d.toLowerCase());
        return this.arraytoComma(fileext);
      }
    },
    isNurse() {
      return this.candidate?.candidate_type_id?.code
        ? this.candidate.candidate_type_id.code === 2
        : false;
    },
    isAHP() {
      return this.candidate?.candidate_type_id?.code === 3 || false;
    },
    isPhysician() {
      return this.candidate?.candidate_type_id?.code
        ? this.candidate?.candidate_type_id?.code === 1
        : false;
    },
    isDentist() {
      return this.candidate?.candidate_type_id?.code
        ? this.candidate?.candidate_type_id?.code === 4
        : false;
    },
    isNonClinicAdmin() {
      return this.candidate?.candidate_type_id?.code
        ? this.candidate?.candidate_type_id?.code === 6
        : false;
    },
    nurseTypes() {
      return (
        this.nurseRegistrationType.map((val) => ({
          code: val,
          label: this.camelize(val),
        })) || []
      );
    },
    firstQualificationIsOther() {
      return (
        this.candidate?.first_qualification_id?.label.includes(
          "Other - not listed"
        ) || false
      );
    },
    mainQualificationIsOther() {
      return (
        this.candidate?.main_qualification_id?.label.includes(
          "Other - not listed"
        ) || false
      );
    },
    specialityIsOther() {
      return (
        this.candidate?.speciality_id?.label.includes("Other - not listed") ||
        false
      );
    },
    isOther() {
      return (
        (this.candidate?.other_areas_of_expertise_ids &&
          this.candidate?.other_areas_of_expertise_ids.some(
            ({ label }) => label === "Other - not listed"
          )) ||
        false
      );
    },
    hospitalName() {
      return getLocalOrgDetail()?.name ? getLocalOrgDetail()?.name : "";
    },
    isParent() {
      return getLocalOrgDetail()?.is_parent || false;
    },
  },
  watch: {
    uploaded() {
      if (this.uploaded) {
        this.url = this.profilePhoto.value.croppedImageSrc;
        this.$refs.profilefileref.value = "";
        this.isImageSelected = false;
        this.fileData = {};
      }
    },
    payload() {
      this.options["subSpeciality"].length <= 0
        ? this.changeFieldValues("sub_speciality_id", null)
        : null;
      this.payload.main_qualification_speciality_id = this.payload.speciality_id
        ? this.payload.speciality_id
        : null;
    },
    "payload.first_qualification_country_id"() {
      this.changeFieldValues("first_qualification_id", null);
      if (this.payload?.first_qualification_country_id) {
        let first_qual_country = _.filter(
          this.options["locationInfo"],
          (option) => {
            return this.payload?.first_qualification_country_id == option?.code;
          }
        );
        this.candidate.main_qualification_country_id = first_qual_country[0];
        this.payload.main_qualification_country_id =
          first_qual_country[0]?.code;
        this.fetchHigherQualificationByCountryAndCandidateType({
          country_id: this.payload.main_qualification_country_id,
          candidate_type_id: this.payload.candidate_type_id,
        });
      }
    },
    "payload.main_qualification_country_id"() {
      this.changeFieldValues("main_qualification_id", null);
    },
    "payload.candidate_type_id"() {
      this.changeFieldValues("main_qualification_country_id", null);
    },
    "candidate.main_qualification_id"() {
      this.candidate.main_qualification_id?.specialist_registration_reqd ==
      false
        ? this.changeFieldValues("specialist_registration", null)
        : null;
    },
    "payload.sub_speciality_id"() {
      this.payload.main_qualification_sub_speciality_id = this.payload.sub_speciality_id;
    },

    "payload.specialist_registration"() {
      this.payload.specialist_registration
        ? null
        : this.changeFieldValues("date_acquired", null);
    },
    "candidate.isRegisteredWhatsapp"() {
      this.candidate.isRegisteredWhatsapp
        ? ((this.candidate.whatsapp = this.candidate.phone),
          (this.payload.whatsapp = this.payload.phone))
        : this.changeFieldValues("whatsapp", null);
    },
    "candidate.preferred_contact_method"() {
      this.candidate.preferred_contact_method.some((el) => el.id == "whatsapp")
        ? (this.candidate.isRegisteredWhatsapp = true)
        : null;
    },
    isCandidateSupplier() {
      this.displayPage = 4;
    },
    getFilteredIssueAuthority() {
      if (this.getFilteredIssueAuthority?.length) {
        this.candidate.main_qualification_issue_authority_id = this.options[
          "issued_by"
        ][0];
        this.payload.main_qualification_issue_authority_id = this.options[
          "issued_by"
        ][0].code;
      } else {
        this.payload.main_qualification_issue_authority_id = this.candidate.main_qualification_issue_authority_id = null;
      }
    },
  },
  mounted() {
    this.initQuickRegisterFetchOptions();
    this.fetchIssueAuthorityByQualification();
    this.fetchQualificationByCountryAndCandidateType();
    this.fetchDepartments({ organisation_id: this.organisationID });
    let isMobile = window.matchMedia("only screen and (max-width: 400px)")
      .matches;
    this.isMobile = isMobile;
    this.organisationID
      ? (this.payload["organisation_id"] = this.organisationID)
      : null;
    this.locationID ? (this.payload["location_id"] = this.locationID) : null;
    this.fetchNurseRegistrationType({ customer_id: this.customer });
    this.uniquecode = this.$router.currentRoute.params.code
    
  },
  methods: {
    ...mapActions([
      "initQuickRegisterFetchOptions",
      "showToast",
      "multipleFileUpload",
      "fetchQualificationByCandidateType",
      "fetchQualificationByCountryAndCandidateType",
      "fetchIssueAuthorityByQualification",
      "fetchDepartments",
      "fetchQualificationByCountryAndCandidateTypeWithoutGetter",
      "fetchFirstQualificationByCountryAndCandidateType",
      "fetchHigherQualificationByCountryAndCandidateType",
      "fetchNurseRegistrationType",
      "fetchIssueAuthorityByParams",
      "getRegisteredHospitalsByEmail",
      "registerCommonCandidate",
      "uploadTempDoc",
      "getTempDocs",
      "resetCandidate",
      "getRegisteredOhrCandidateByEmail",
    ]),
    handleInput(name, value) {
      Vue.set(this.candidate, name, value);
      Vue.set(this.payload, name, value);
      let hasError = {
        phone: "phone",
        whatsapp: "whatsapp",
      };
      if ((name === hasError.phone || hasError.whatsapp) && value === "") {
        this.clearFieldErrors(name, hasError);
      }
      let email_name = "email";
      // if (name === "alternate_email") {
      // Vue.set(this.candidate, email_name, value);
      // Vue.set(this.payload, email_name, value);

      // // Check email is already registered
      // this.getRegisteredHospitalsByEmail(value).then(() => {
      //   if (this.getRegisteredParent.length) {
      //     this.alreadyRegisteredModal.innerHTML = `
      //     <p>
      //     You have already registered with the following hospital(s) <em style="font-weight: bold;">${this.getRegisteredParent.join(', ')}</em>.
      //     Do you wish to move forward?
      //     </p>
      //     `
      //     this.alreadyRegisteredModal.isShowPopup = true;
      //   }
      // });
      // }
    },
    changeFieldValues(name, value) {
      Vue.set(this.candidate, name, value);
      Vue.set(this.payload, name, value);
    },
    handleEmailChange(name, value) {
      if (
        !this.checkEmptyString(this.candidate["alternate_email"]) &&
        this.candidate["email"] != this.candidate["alternate_email"]
      ) {
        this.emailConfirmModal.isShowPopup = true;
        Vue.set(this.candidate, "share_profile", false);
        Vue.set(this.payload, "share_profile", false);
      }
      // // Check email is already registered
      // if (!this.payload.share_profile) {
      //   this.getRegisteredHospitalsByEmail(value).then(() => {
      //     if (this.getRegisteredParent.length) {
      //       this.alreadyRegisteredModal.innerHTML = `
      //       <p>
      //       You have already registered with the following hospital(s) <em style="font-weight: bold;">${this.getRegisteredParent.join(', ')}</em>.
      //       Do you wish to move forward?
      //       </p>
      //       `
      //       this.alreadyRegisteredModal.isShowPopup = true;
      //     }
      //   });
      // }
    },
    checkEmptyString(str) {
      if (str != null) return str.trim() === "";
      return true;
    },
    handleDatePickerChange(name, value) {
      Vue.set(this.candidate, name, value);
      if (name == "qualified_date") {
        this.payload.main_qualification_month_awarded = value
          ? m(value).month() + 1
          : null;
        this.payload.main_qualification_year_awarded = value
          ? m(value).year()
          : null;
        return;
      }
      if (name == "first_qualified_date") {
        this.payload.first_qualification_month_awarded = value
          ? m(value).month() + 1
          : null;
        this.payload.first_qualification_year_awarded = value
          ? m(value).year()
          : null;
        return;
      }
      Vue.set(this.payload, name, m(value).format("YYYY-MM-DD"));
    },
    handleChangeSelect(name, value) {
      if (
        name === "speciality_id" &&
        this.payload?.other_areas_of_expertise_ids &&
        this.payload?.other_areas_of_expertise_ids.includes(value?.code)
      ) {
        this.showToast({
          class: "bg-danger text-white",
          message:
            "Speciality already exists! Please select another Speciality",
        });
        return;
      }
      if (name == "candidate_type_id") {
        const professionFields = [
          "main_qualification_id",
          "other_qualification_name",
          "main_qualification_year_awarded",
          "main_qualification_month_awarded",
          "main_qualification_country_id",
          "main_qualification_course_duration",
          "main_qualification_description",
          "main_qualification_by_exam",
          "main_qualification_other_issue_authority",
          "main_qualification_issue_authority_id",
          "speciality_id",
          "first_qualified_date",
          "qualified_date",
          "sub_peciality_id",
          "speciality_duration",
          "first_other_qualification_name",
          "first_qualification_id",
          "first_qualification_year_awarded",
          "first_qualification_month_awarded",
          "first_qualification_country_id",
          "first_qualification_distance_learning",
          "first_qualification_issue_authority_id",
          "first_qualification_issue_authority",
          "first_qualification_course_duration",
          "first_qualification_description",
          "first_qualification_by_exam",
          "level_id",
          "acquired_date",
          "specialist_registration",
        ];
        _.forEach(professionFields, (field) => {
          this.changeFieldValues(field, null);
        });
        this.changeFieldValues("is_have_higher_qualification", false);
        this.fetchQualificationByCountryAndCandidateType();
        this.fetchFirstQualificationByCountryAndCandidateType();
        this.fetchHigherQualificationByCountryAndCandidateType();
      }
      Vue.set(this.candidate, name, value);
      let document_type = "document_type";
      let preferred_contact_method = "preferred_contact_method";
      if (name == preferred_contact_method) {
        let temp = "";
        value.forEach((ele) => {
          temp = temp ? temp + ", " + ele.id : ele.id;
        });
        value = temp;
      }
      if (name != document_type) {
        this.payload = {
          ...this.payload,
          [name]: value ? value.id || value.code || value : null,
        };
      }
      let hasError = {
        month_awarded: "main_qualification_month_awarded",
      };
      if (name == hasError.month_awarded && !value) {
        this.clearFieldErrors(name, hasError);
      }
      let mainQualification = "main_qualification_id";
      if (name === mainQualification) {
        this.handleChangeRadio(
          "main_qualification_by_exam",
          value?.by_exam ? true : null
        );
        this.fetchIssueAuthorityByQualification({
          qualification_id: this.payload.main_qualification_id,
        });
      }
      if (
        name == "candidate_type_id" ||
        name == "main_qualification_country_id"
      ) {
        if (
          name == "main_qualification_country_id" &&
          (this.isNurse || this.isAHP)
        ) {
          this.fetchHigherQualificationByCountryAndCandidateType({
            country_id: this.payload.main_qualification_country_id,
            candidate_type_id: this.payload.candidate_type_id,
          });
        } else if (
          (name == "candidate_type_id" &&
            !this.isNurse &&
            !this.isNonClinicAdmin) ||
          (!this.isAHP &&
            this.payload.main_qualification_country_id &&
            this.payload.candidate_type_id)
        ) {
          this.fetchQualificationByCountryAndCandidateType({
            country_id: this.payload.main_qualification_country_id,
            candidate_type_id: this.payload.candidate_type_id,
          });
        } else if (
          name == "candidate_type_id" &&
          this.isNonClinicAdmin &&
          this.payload.candidate_type_id
        ) {
          this.fetchQualificationByCandidateType({
            candidate_type_id: this.payload.candidate_type_id,
          });
        }
        this.nurseRegistrationType.forEach((element) => {
          this.candidate[element] = false;
          this.payload[element] = false;
        });
      }
      if (
        name == "candidate_type_id" ||
        name == "first_qualification_country_id"
      ) {
        if (
          (name == "first_qualification_country_id" && this.isNurse) ||
          (this.isAHP &&
            this.payload.first_qualification_country_id &&
            this.payload.candidate_type_id)
        ) {
          this.fetchFirstQualificationByCountryAndCandidateType({
            country_id: this.payload.first_qualification_country_id,
            candidate_type_id: this.payload.candidate_type_id,
          });
        } else if (
          (name == "candidate_type_id" && !this.isNurse) ||
          (!this.isAHP &&
            this.payload.first_qualification_country_id &&
            this.payload.candidate_type_id)
        ) {
          this.fetchQualificationByCountryAndCandidateType({
            country_id: this.payload.first_qualification_country_id,
            candidate_type_id: this.payload.candidate_type_id,
          });
        }
      }
      // if (name == 'main_qualification_country_id' || name == 'first_qualification_country_id') {
      //   let issueAuthorityPayload = {
      //     candidate_type_id: this.payload.candidate_type_id,
      //     country_id: value?.code,
      //     is_qualification: true
      //   }
      //   this.fetchIssueAuthorityByParams(issueAuthorityPayload);
      // }
    },
    handleChangeMultiSelectAsArray(name, value) {
      if (
        name === "other_areas_of_expertise_ids" &&
        value &&
        value.some(({ code }) => code === this.payload?.speciality_id)
      ) {
        this.showToast({
          class: "bg-danger text-white",
          message:
            "Speciality already exists! Please select another Speciality",
        });
        return;
      }
      Vue.set(this.candidate, name, value);
      this.payload = {
        ...this.payload,
        [name]: value
          ? value.map((v) => {
              return v.id || v.code || v;
            })
          : null,
      };
    },
    handleChangeCheck(name, $event) {
      const checked = $event.target.checked;
      Vue.set(this.candidate, name, checked);
    },
    goToLogin() {
      if (this.getOrgVariant == Variant.campaign) {
        this.resetCandidate();
        let {
          path_acronym,
          customer_uid,
          organisation_id,
          location_id,
        } = getLocalOrgDetail();

        let path = `/${path_acronym}/register/${customer_uid}`;
        if (organisation_id)
          path += `/organisation/${organisation_id}/location/${location_id}`;

        this.$router.push({ path: path });
        this.displayPage = 1;
        this.resetForm();
      } else {
        this.$router.push({ path: LOGIN_URL() });
      }
    },
    setCurrentData(name, value) {
      Vue.set(this.candidate, name, value);
      Vue.set(this.payload, name, value);
    },
    handleChangeRadio(name, value) {
      let val = null;
      if (value?.id === false || value?.code === false) {
        val = false;
      } else {
        val = value?.id || value?.code || value;
      }
      Vue.set(this.candidate, name, value);
      this.payload = {
        ...this.payload,
        [name]: val,
      };
      if (this.nurseRegistrationType.includes(name) && val) {
        this.nurseRegistrationType.forEach((element) => {
          name !== element ? this.handleChangeRadio(element, false) : null;
        });
      }
    },
    handleFile(event) {
      let docType = this.candidate.document_type;
      if (!docType) {
        let resume_docType = this.documentTypeExcPhoto.filter(
          ({ code }) => code === 1
        );
        docType = resume_docType.length ? resume_docType[0] : [];
        let document_type = "document_type";
        Vue.set(this.candidate, document_type, docType);
      }
      let { name: filename } = event.target.files[0];
      let fileExt = filename.split(".").pop();
      const fileTypeString = this.fileType;
      let versionControl = {
        resume: "CV / Resume",
        coverLetter: "Cover Letter",
        surgicalLogbook: "Surgical Logbook",
      };
      let versionFound = false;
      if (this.documentExists(docType, filename)) {
        this.$refs.docfileref.value = "";
        this.showToast({
          class: "bg-danger text-white",
          message: "Document already exists! Please select another Document",
        });
      } else if (!fileTypeString.includes(fileExt)) {
        this.candidate.document_type = "";
        this.$refs.docfileref.value = "";
        this.showToast({
          class: "bg-danger text-white",
          message: `Allowed File Type ${fileTypeString}`,
        });
      } else {
        if (
          docType.label === versionControl.resume ||
          docType.label === versionControl.coverLetter ||
          docType.label === versionControl.surgicalLogbook
        ) {
          versionFound = this.uploadFiles.some(
            (el) => el.docType.code === docType.code
          );
        }
        this.fileUpload(docType, event, versionFound);
      }
    },
    fileUpload(docType, event, versionFound) {
      const size = event.target.files[0].size;
      if (Math.round(size / (1024 * 1024)) <= 2) {
        this.isFileError = false;
        let ext = event.target.files[0]?.name
          ?.split(".")
          .pop()
          .toUpperCase();
        let fileData = {
          docType,
          file: event.target.files[0],
          fileExt: ext,
          fileName: `${docType?.code}_${event.target.files[0].name}`,
        };
        if (["DOC", "DOCX", "XLS", "XLSX"].includes(ext)) {
          this.uploadTempDoc({ uuid: this.randomUuid, ...fileData }).then(
            (res) => {
              if (res && res.length) {
                let isTemp = true;
                fileData = { ...fileData, isTemp };
                this.insertDoc({ fileData, docType, event, versionFound });
              }
            }
          );
        } else {
          this.insertDoc({ fileData, docType, event, versionFound });
        }
        this.$refs.docfileref.value = "";
        this.candidate.document_type = "";
      } else {
        this.$refs.docfileref.value = "";
        this.isFileError = true;
      }
    },
    insertDoc({ fileData, docType, event, versionFound }) {
      if (versionFound) {
        for (let i = 0; i < this.uploadFiles.length; i++) {
          if (this.uploadFiles[i].docType.code === docType.code) {
            this.$nextTick(() => {
              Vue.set(this.uploadFiles, i, fileData);
            });
          }
        }
      } else {
        this.$nextTick(() => {
          this.uploadFiles.push(fileData);
        });
      }
    },
    handleConfirmPassword(name, value) {
      this[name] = value;
    },
    resetForm() {
      this.fileData = {};
      this.candidate = {
        preferred_contact_method: [],
        isRegisteredWhatsapp: false,
        is_have_higher_qualification: false,
        share_profile: false,
      };
      let email = this.existingCandidate["email"];
      Vue.set(this.candidate, "email", email);
      Vue.set(this.candidate, "alternate_email", email);
      Vue.set(this.payload, "email", email);
      Vue.set(this.payload, "alternate_email", email);
      this.existingCandidate = { email };
      this.confirmPassword = null;
      this.$refs.phoneNo?.resetPhoneno();
      this.$refs.whatsappNo?.resetPhoneno();
    },
    async onSubmit() {
      const isValid = await this.$refs.register.validate();
      const errors = Object.values(this.$refs.register.errors).flat();
      if (this.isNurse || this.isAHP) {
        this.payload.first_qualification_country_id = this.candidate?.first_qualification_country_id?.code;
        this.payload.main_qualification_country_id = this.candidate?.main_qualification_country_id?.code;
        this.payload.main_qualification_course_duration = this.candidate.main_qualification_course_duration;
        this.payload.first_qualification_course_duration = this.candidate.first_qualification_course_duration;
        this.payload.speciality_duration = this.candidate.speciality_duration;
      }
      this.payload.years_at_level = this.candidate.years_at_level;
      if (this.isCandidateSupplier && this.getUserContactId && this.getUser) {
        this.payload.customer_id = this.customer;
        (this.payload.contact_id = this.getUserContactId),
          (this.payload.created_by = this.getUser?.id
            ? this.getUser?.id
            : null);
      }
      if (!isValid) {
        let el = this.$el.getElementsByClassName("has-error")[0];
        el.scrollIntoView(true);
        window.scrollBy(0, -140);
        this.showToast({
          class: "bg-danger text-white",
          message: errors[0] || "please fill mandatory fields!",
        });
        return;
      } else if (
        !this.isNurse &&
        !this.isAHP &&
        this.candidate.main_qualification_id.course_duration_reqd &&
        !this.payload.main_qualification_course_duration
      ) {
        let el = this.$el.getElementsByClassName("course_duration")[0];
        el.scrollIntoView(true);
        window.scrollBy(0, -140);
        this.showToast({
          class: "bg-danger text-white",
          message: "Qualification Course Duration can't be less than one year!",
        });
      } else if (
        (this.isNurse || this.isAHP) &&
        !this.payload.speciality_duration
      ) {
        let el = this.$el.getElementsByClassName("speciality_duration")[0];
        el.scrollIntoView(true);
        window.scrollBy(0, -140);
        this.showToast({
          class: "bg-danger text-white",
          message: "Years in speciality cannot be less than one year!",
        });
      } else if (
        !this.payload.first_qualification_course_duration &&
        (this.isNurse || this.isAHP)
      ) {
        let el = this.$el.getElementsByClassName("first_course_duration")[0];
        el.scrollIntoView(true);
        window.scrollBy(0, -140);
        this.showToast({
          class: "bg-danger text-white",
          message: "Qualification Course Duration can't be less than one year!",
        });
      } else if (
        (this.isNurse || this.isAHP) &&
        this.payload.is_have_higher_qualification &&
        !this.payload.main_qualification_course_duration
      ) {
        let el = this.$el.getElementsByClassName("course_duration")[0];
        el.scrollIntoView(true);
        window.scrollBy(0, -140);
        this.showToast({
          class: "bg-danger text-white",
          message: "Qualification Course Duration can't be less than one year!",
        });
      } else if (
        (this.isNurse || this.isAHP) &&
        this.payload?.is_have_higher_qualification &&
        this.payload?.first_qualification_id ===
          this.payload?.main_qualification_id
      ) {
        let el = this.$el.getElementsByClassName("main_qualification")[0];
        el.scrollIntoView(true);
        window.scrollBy(0, -140);
        this.showToast({
          class: "bg-danger text-white",
          message: "First and Main Qualifications are Same!",
        });
      } else if (this.isTermPrivacyChecked) {
        !this.payload.sub_speciality_id
          ? delete this.payload.sub_speciality_id
          : null;
        this.payload.main_qualification_issue_authority_id === "Others"
          ? (this.payload.main_qualification_issue_authority_id = null)
          : null;
        this.payload.main_qualification_issue_authority_id === "Others"
          ? delete this.payload.main_qualification_other_issue_authority
          : null;
        this.candidate.isRegisteredWhatsapp
          ? (this.payload.whatsapp = this.payload.phone)
          : null;
        this.candidate.main_qualification_id?.label?.includes(
          "Other - not listed"
        )
          ? null
          : delete this.payload.other_qualification_name;
        // !this.candidate?.main_qualification_id?.allow_subject_input
        //   ? delete this.payload.main_qualification_description
        //   : null;
        this.payload = {
          ...this.payload,
          privacy_acceptance: this.isTermPrivacyChecked,
        };
        this.payload.first_qualification_issue_authority_id === "Others"
          ? (this.payload.first_qualification_issue_authority_id = null)
          : null;

        if (
          this.payload?.speciality_id?.label?.includes("Other - not listed")
        ) {
          this.payload.speciality_id = null;
          this.payload.main_qualification_speciality_id = null;
        } else {
          this.payload.other_speciality = null;
        }
        if (
          this.candidate?.other_areas_of_expertise_ids &&
          this.candidate?.other_areas_of_expertise_ids.some(
            ({ label }) => label === "Other - not listed"
          )
        ) {
          this.payload.other_areas_of_expertise_ids = this.candidate.other_areas_of_expertise_ids.map(
            (v) => {
              return v.label === "Other - not listed"
                ? {
                    id: v.code || null,
                    other_name: this.payload?.other_areas_of_expertise_name,
                  }
                : v.code;
            }
          );
        }
        this.payload.other_areas_of_expertise_name
          ? delete this.payload.other_areas_of_expertise_name
          : null;
        let finalPayload = { ...this.payload };
        const deleteNurseFields = [
          "first_other_qualification_name",
          "first_qualification_id",
          "first_qualification_year_awarded",
          "first_qualification_month_awarded",
          "first_qualification_country_id",
          "first_qualification_distance_learning",
          "first_qualification_issue_authority_id",
          "first_qualification_issue_authority",
          "first_qualification_course_duration",
          "first_qualification_description",
          "first_qualification_by_exam",
        ];
        if (!this.isNurse && !this.isAHP) {
          this.deleteFields(deleteNurseFields);
          this.deleteFields(["other_areas_of_expertise_ids", "level_id"]);
        } else if (this.isAHP) {
          this.deleteFields(["level_id"]);
        }
        if (this.isNurse || this.isAHP) {
          if (!this.payload.is_have_higher_qualification) {
            const temp = {
              main_qualification_id: this.payload?.first_qualification_id,
              main_qualification_year_awarded: this.payload
                ?.first_qualification_year_awarded,
              main_qualification_month_awarded: this.payload
                ?.first_qualification_month_awarded,
              main_qualification_country_id: this.payload
                ?.first_qualification_country_id,
              main_qualification_course_duration: this.payload
                ?.first_qualification_course_duration,
              main_qualification_description: this.payload
                ?.first_qualification_description,
              main_qualification_by_exam: this.payload
                ?.first_qualification_by_exam,
              other_qualification_name: this.payload
                ?.first_other_qualification_name,
            };
            finalPayload = { ...finalPayload, ...temp };
            deleteNurseFields.forEach((field) => {
              delete finalPayload[field];
            });
          }
          if (this.isNurse) {
            let nurse_registration_type = [];
            this.nurseRegistrationType.forEach((e) => {
              finalPayload[e] === true ? nurse_registration_type.push(e) : null;
              delete finalPayload[e];
            });
            finalPayload[
              "nurse_registration_type"
            ] = nurse_registration_type.length ? nurse_registration_type : null;
          }
        }
        let payload_data = {
          payload: finalPayload,
          docFiles: this.uploadFiles,
        };
        this.$emit("submit", payload_data);
      } else {
        this.termConfirmModal.isShowPopup = true;
      }
    },
    selectedFile(event) {
      const file = event.target.files[0];
      this.isImageSelected = true;
      this.fileData = file;
    },
    closeModal() {
      this.$refs.profilefileref.value = "";
      this.fileData = {};
      this.isImageSelected = false;
    },
    arraytoComma(d) {
      return d?.map((v) => "." + v).join(", ");
    },
    documentExists(docType, filename) {
      let files = [];
      this.uploadFiles.forEach((element) => {
        if (element.docType.code === docType.code) {
          files.push(element);
        }
      });
      let fileFound = files.some((el) => el.file.name === filename);
      return fileFound ? true : false;
    },
    deleteDocuments(fileName, index) {
      this.deleteConfirmModal.modalContent = `Do you want to delete this document - ${fileName} ?`;
      this.document_id = index;
      this.deleteConfirmModal.isShowPopup = true;
    },
    deletemodalCallBack(action) {
      let confirmDelete = "Confirm";
      if (action === confirmDelete) {
        this.uploadFiles.splice(this.document_id, 1);
      }
      this.document_id = "";
      this.deleteConfirmModal.isShowPopup = false;
    },
    emailmodalCallBack(action) {
      let confirmEmail = "Confirm";
      if (action === confirmEmail) {
        let alternet_email = "alternate_email";
        Vue.set(this.candidate, alternet_email, this.candidate["email"]);
        Vue.set(this.payload, alternet_email, this.candidate["email"]);
      }
      this.emailConfirmModal.isShowPopup = false;
    },
    proceedToMultiRegistration(action) {
      if (action === "Continue") {
        Vue.set(this.candidate, "share_profile", true);
        Vue.set(this.payload, "share_profile", true);
      }
      if (!action) {
        Vue.set(this.candidate, "email", "");
        Vue.set(this.payload, "email", "");
      }
      this.alreadyRegisteredModal.isShowPopup = false;
    },
    pad(n, width, z) {
      z = z || "0";
      n = n + "";
      return n.length >= width
        ? n
        : new Array(width - n.length + 1).join(z) + n;
    },
    clearFieldErrors(name, hasError) {
      this.$nextTick(() => {
        if (name == hasError.phone) {
          this.$refs.phone.errors.clear;
          this.$refs.phone.reset();
        } else if (name == hasError.whatsapp) {
          this.$refs.whatsapp.errors.clear;
          this.$refs.whatsapp.reset();
        } else if (name == hasError.month_awarded) {
          this.$refs.month_awarded.errors.clear;
          this.$refs.month_awarded.reset();
        }
      });
    },
    handleSlider: debounce(function(value) {
      Vue.set(this.candidate, "main_qualification_course_duration", value);
      Vue.set(this.payload, "main_qualification_course_duration", value);
    }, 500),
    handleAcquiredDate(name, value) {
      this[name] = value;
      let acquired_date = value.split("-");
      let spl_year = "specialist_registration_year";
      let spl_mth = "specialist_registration_month";
      Vue.set(this.payload, spl_year, parseInt(acquired_date[0]));
      Vue.set(this.payload, spl_mth, parseInt(acquired_date[1]));
    },
    showTermPopup() {
      let data = {
        document_ext: "PDF",
        link: "/doc/Talentfind_privacy_policy.pdf",
      };
      if (data.document_ext === "PDF") {
        this.iframe.isImg = false;
        this.iframe.loaded = false;
        this.iframe.pdf.ispdf = true;
        this.iframe.pdf.src = pdf.createLoadingTask(data.link);
        setTimeout(() => {
          this.iframe.pdf.src.promise.then((pdf) => {
            this.iframe.pdf.numPages = pdf.numPages;
          });
        }, 100);
        this.previewModal.modalTitle = "Terms and Privacy Policy";
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      }
    },
    preview(data) {
      this.previewModal.modalTitle = "Document Preview";
      let src = URL.createObjectURL(data.file);
      if (
        data.fileExt === "PNG" ||
        data.fileExt === "JPG" ||
        data.fileExt === "JPEG" ||
        data.fileExt === "GIF"
      ) {
        this.iframe.loaded = false;
        this.iframe.ispdf = false;
        this.iframe.isImg = true;
        this.iframe.pdf.ispdf = false;
        this.iframe.src = src;
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (data.fileExt === "PDF") {
        this.iframe.isImg = false;
        this.iframe.loaded = false;
        this.iframe.pdf.ispdf = true;
        this.iframe.pdf.src = pdf.createLoadingTask(src);
        setTimeout(() => {
          this.iframe.pdf.src.promise.then((pdf) => {
            this.iframe.pdf.numPages = pdf.numPages;
          });
        }, 100);
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else {
        this.showToast({
          class: "bg-danger text-white",
          message: "The selected file type is not available for Preview.",
        });
        this.iframe.src = "";
        this.iframe.loaded = false;
        this.iframe.isImg = false;
        this.iframe.pdf.ispdf = false;
        this.previewModal.isPreview = false;
        this.previewModal.isShowPopup = false;
      }
    },
    previewTemp(data) {
      const { fileName } = data;
      let payload = { uuid: this.randomUuid, file_name: fileName };
      this.getTempDocs(payload).then((res) => {
        this.iframe.isImg = false;
        this.iframe.pdf.ispdf = false;
        this.iframe.loaded = true;
        this.iframe.src =
          "https://view.officeapps.live.com/op/embed.aspx?src=" + res;
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      });
      this.previewModal.modalTitle = "Document Preview";
    },
    modalCallBack(action) {
      this.previewModal.isPreview = false;
      this.previewModal.isShowPopup = false;
      this.iframe.src = "";
      this.iframe.loaded = false;
      this.iframe.isImg = false;
      this.iframe.pdf.ispdf = false;
    },
    termmodalCallBack(action) {
      this.termConfirmModal.isShowPopup = false;
    },
    deleteFields(deleteFields) {
      deleteFields.forEach((field) => {
        delete this.payload[field];
      });
    },
    camelize(str) {
      return _.startCase(str);
    },
    handleExistingCandidateInput(name, value) {
      Vue.set(this.existingCandidate, name, value);
      if (name === "email") {
        Vue.set(this.candidate, "email", value);
        Vue.set(this.candidate, "alternate_email", value);
        Vue.set(this.payload, "email", value);
        Vue.set(this.payload, "alternate_email", value);
      }
    },
    onExistingCandidate() {
      const isValid = this.$refs.existingCandidatePassword.validate();
      const isPasswordEntered = this.existingCandidate?.password;
      if (isPasswordEntered) {
        let payload = {
          ...this.existingCandidate,
          customer_uid: this.customer,
          organisation_id: this.organisationID ? this.organisationID : null,
          location_id: this.locationID ? this.locationID : null,
        };
        this.registerCommonCandidate(payload);
      }
    },
    checkCandidateExists() {
       // this.isLoading = true;
      if (!this.existingCandidate["email"]) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Enter valid email!",
        });
        this.isLoading = false;
        return false;
      }
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(this.existingCandidate["email"]).toLowerCase())) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Enter valid email!",
        });
        this.isLoading = false;
        return false;
      }
      const payload = {
        email: this.existingCandidate["email"],
        customer_uid: this.customer,
        organisation_uid: this.organisationID,
      };
      this.getRegisteredHospitalsByEmail(payload).then((res) => {
        this.isLoading = false;              
        const errMsg =  res?.response?.data?.detail?.message ||  res?.response?.data?.detail;
        if (
          res?.response?.status === 400 &&
          errMsg?.includes("Already registered")
        ) {
          this.displayPage = 3;
          this.showToast({
            class: "bg-danger text-white",
            message: `You are already registered`,
          });
        } else {
          let data = {
            email: this.existingCandidate["email"],
            customer_uid: this.customer,
          };
          this.getRegisteredHospitalsByEmail(data).then((res) => {
            if (this.getRegisteredParent?.length) {
              if (this.getRegisteredParent == "OHR") {
                this.displayPage = 3;
                this.showToast({
                  class: "bg-danger text-white",
                  message: `You are already registered `,
                });
              } else this.displayPage = 2;
            } else {
              
              const payload = {
        email: this.existingCandidate["email"],
        uniquecode: this.uniquecode,
      
      };
      this.getRegisteredOhrCandidateByEmail(payload).then((res)=>{
        this.displayPage = 4;
        this.isLoading = false;
         let datadetails= this.ohrcandidateProfile;
        
        Vue.set(this.candidate, "first_name", datadetails.first_name);
        Vue.set(this.candidate, "surname", datadetails.surname);
        Vue.set(this.candidate, "dob", datadetails.dob);
        Vue.set(this.candidate, "existingphone",datadetails.phone)
        Vue.set(this.payload, "first_name", datadetails.first_name);
        Vue.set(this.payload, "surname", datadetails.surname);
        Vue.set(this.payload, "dob", datadetails.dob);
        if(datadetails.gender == "Male")
        { 
          Vue.set(this.payload, "gender_id", 1);
         Vue.set(this.candidate, "gender_id","Male");
        }
        else if(datadetails.gender == "Female")
        { 
          Vue.set(this.payload, "gender_id", 2);
          Vue.set(this.candidate, "gender_id", "Female");
        }
         Vue.set(this.payload,"original_ohr_id" , this.uniquecode);
         this.handleChangeMultiSelectAsArray("nationality_ids",[{"code": datadetails.nationality_id1,"nationality":datadetails.nationality1,"label":datadetails.nationality1}])
         this.handleChangeSelect("speciality_id",{"code": datadetails.speciality_id,"speciality":datadetails.speciality,"label":datadetails.speciality})
      }).catch((err)=>{
          this.displayPage = 1;
          this.showToast({
                  class: "bg-danger text-white",
                  message: `Email is not match.Enter Correct Email `,
                });
      });
      
            }
          });
        }
      });
      
  
    },
  },
};
</script>

<style lang="scss" scoped>
.hint {
  opacity: 0.7;
}
.img-width {
  width: 100%;
}
@media all and (max-width: 375px) {
  .text-center > .btn {
    width: 145px;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
.action-button {
  width: 14px;
  height: 14px;
  border: 0;
  background: transparent;
  padding: 0;
  outline: none;
}
.registered-whatsapp {
  padding-top: 5px;
}
@media all and (max-width: 480px) {
  .registered-whatsapp {
    padding-top: 0%;
  }
}
.center-align {
  display: flex;
  justify-content: center;
  align-items: center;
}
#hospital-list {
  padding-left: 0.5rem;
}
.cursor-action {
  cursor: pointer;
}
.cursor-action:hover {
  text-decoration: underline;
}
</style>
